import React from 'react';
import { Button } from 'antd';
import Link from 'next/link';
import Head from 'next/head';

const Error = () => (
  <>
    <Head>
      <title>Страница не найдена &#8212; магазин оптики SI OPTICS в Москве</title>
    </Head>
    <div className="page page404">
      <h1>Страница не найдена</h1>
      <div className="goggle-container">
        <div className="goggle">
          <div className="glass left" />
          <div className="bridge" />
          <div className="glass right" />
          <div className="leg left" />
          <div className="leg right" />
        </div>
      </div>
      <Link href="/">
        <Button type="link" size="large">
          Перейти на главную
        </Button>
      </Link>
    </div>
  </>
);

export default Error;
